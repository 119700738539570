import kit from "./header/kit.png";
import pills from "./header/pills.png";
import scope from "./header/scope.png";
import bottle from "./header/bottle.png";

// products
import firstAid from "./products/asset1.jpg";
import baby from "./products/asset2.jpg";
import health from "./products/asset3.jpg";
import eye from "./products/asset4.jpg";
import food from "./products/asset5.jpg";
import sleep from "./products/asset6.jpg";
import pain from "./products/asset7.jpg";
import family from "./products/asset8.jpg";
import skin from "./products/asset9.jpg";

export const KitIMG = kit;
export const pillsIMG = pills;
export const scopeIMG = scope;
export const bottleIMG = bottle;

export const firstAidIMG = firstAid;
export const babyIMG = baby;
export const healthIMG = health;
export const eyeIMG = eye;
export const foodIMG = food;
export const sleepIMG = sleep;
export const painIMG = pain;
export const familyIMG = family;
export const skinIMG = skin;
