import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { routes } from "../../constants/routes";
import { TopNavBar } from "../TopNavBar";

export const MainContainer = () => {
  return (
    <>
      <TopNavBar />
      <Routes>
        {routes.map(({ path, component }) => (
          <Route path={path} element={component} key={path} />
        ))}
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </>
  );
};
