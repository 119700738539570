export const HyperLink = ({ children, type, link }) => {
  function getType() {
    switch (type) {
      case "email":
        return "mailto";
      case "tel":
        return "tel";
      default:
        break;
    }
  }
  return <a href={`${getType()}:${link || children}`}>{children}</a>;
};
