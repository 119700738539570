import React from "react";
import styled from "styled-components";
import { whyOptions } from "../constants/siteData";
import { MainButton } from "../components/common/Buttons";
import { primaryColor } from "../theme/Theme";

const Item = ({ data: { label, desc, icon: Icon } }) => {
  return (
    <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
      <ItemWrapper className="animate flexColumn gap20">
        <Icon
          className="colorSecondary"
          style={{
            height: 50,
            width: 50,
          }}
        />
        <p className="colorSecondary semiBold font20">{label}</p>
        <p className="font14 light">{desc}</p>
      </ItemWrapper>
    </div>
  );
};

export const Why = () => {
  return (
    <Wrapper id="why us">
      <div className="container flexColumn gap20">
        <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="0">
          <h1 className="font40 colorSecondary textCenter">Why Choose Us</h1>
        </div>
        <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
          <p className="textCenter">
            We provide the <b>Perfect</b> Solution for your health.
          </p>
        </div>

        <GridWrapper>
          {whyOptions.map((data) => (
            <Item data={data} />
          ))}
        </GridWrapper>

        <div
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-delay="300"
          className="flexCenter"
        >
          <MainButton>Speak to an Expert</MainButton>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  padding: 120px 0;
  @media (max-width: 960px) {
    padding: 70px 0;
  }
`;

const GridWrapper = styled.div`
  width: 100%;
  column-gap: 100px;
  row-gap: 50px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 50px 0;

  @media (max-width: 960px) {
    gap: 50px;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
  }
`;

const ItemWrapper = styled.div`
  @media (max-width: 960px) {
    gap: 10px;
  }
`;
