import { ArrowForward } from "@mui/icons-material";
import { Button } from "antd";

export const MainButton = ({
  children,
  onClick,
  icon: Icon = ArrowForward,
}) => {
  return (
    <Button
      onClick={onClick}
      type="primary"
      className="flexCenter"
      style={{ height: 40 }}
      icon={<Icon style={{ fontSize: "18px" }} />}
    >
      {children}
    </Button>
  );
};
