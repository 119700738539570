import React from "react";
import styled from "styled-components";
// Assets
import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { Logo } from "./common/Logo";
import { Link } from "react-scroll";
import { navLinks } from "../constants/links";
import { MainButton } from "./common/Buttons";
import { callToAction } from "../utils/Utils";
import { LogoSVG } from "../assets/svg/Svgs";

export default function Sidebar({ sidebarOpen, toggleSidebar }) {
  const openSidebar = () => toggleSidebar(!sidebarOpen);

  return (
    <Wrapper className="animate whiteBg" sidebarOpen={sidebarOpen}>
      <SidebarHeader className="flexSpaceCenter">
        <div className="flexNullCenter">
          <LogoSVG height="100%" width={120} />
        </div>
        <IconButton onClick={openSidebar}>
          <Close />
        </IconButton>
      </SidebarHeader>

      <div className="flexColumn gap30">
        {navLinks.map(({ label }) => (
          <Link
            className="font15"
            smooth
            offset={-50}
            to={label.toLowerCase()}
            onClick={openSidebar}
          >
            {label}
          </Link>
        ))}
      </div>

      <ButtonWrapper>
        <MainButton onClick={callToAction}>Call to Order</MainButton>
      </ButtonWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.nav`
  max-width: 500px;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  padding: 0 40px;
  right: ${(props) => (props.sidebarOpen ? "0px" : "-100%")};
  z-index: 9999;
`;
const SidebarHeader = styled.div`
  padding: 20px 0;
`;

const ButtonWrapper = styled.div`
  margin-top: 50px;
`;
