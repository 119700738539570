import React from "react";
import styled from "styled-components";
import { headerText } from "../constants/siteData";
import { MainButton } from "../components/common/Buttons";
import { KitIMG, bottleIMG, pillsIMG, scopeIMG } from "../assets/img/Images";
import { callToAction } from "../utils/Utils";
import { APP_NAME } from "../constants/strings";

export const Header = () => {
  // function onMouseMove(event) {
  //   const { top, bottom, left, right } = event.target.getBoundingClientRect();

  //   const middleX = (right - left) / 2;
  //   const middleY = (bottom - top) / 2;

  //   const clientX = event.clientX;
  //   const clientY = event.clientY;

  //   const offsetX = (clientX - middleX) / middleX;
  //   const offsetY = (middleY - clientY) / middleY;

  //   event.target.style.transform = `perspective(1000px) rotateY(${
  //     offsetX * 5
  //   }deg) rotateX(${offsetY * 5}deg) scale3d(1, 1, 1)`;
  // }

  return (
    <div className="meshBg1">
      <Wrapper id="home" className="container">
        <LeftSide className="flex flexColumn">
          <div data-aos="fade-up" data-aos-delay="300" data-aos-duration="1500">
            <h1 className="">
              <span className="mainColor font50">Your Partner in</span>
              <br />
              <span className="extraBold colorSecondary font60">
                Health & Wellness
              </span>
            </h1>
          </div>

          <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="600">
            <TextWrapper>
              <p className="font15">{headerText}</p>
            </TextWrapper>
          </div>

          <div
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-delay="1000"
          >
            <CenterWrapper>
              <MainButton onClick={callToAction}>Call to Order</MainButton>
            </CenterWrapper>
          </div>
        </LeftSide>

        <RightSide className="pokaPattern flexCenter">
          <ImgWrapper1 className="animate floating2">
            <img
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-duration="1500"
              src={KitIMG}
              alt={APP_NAME}
            />
          </ImgWrapper1>
          <ImgWrapper2 className="animate floating3">
            <img
              data-aos="fade-up"
              data-aos-delay="1500"
              data-aos-duration="1500"
              src={pillsIMG}
              alt={APP_NAME}
            />
          </ImgWrapper2>
          <ImgWrapper3 className="animate floating3">
            <img
              data-aos="fade-up"
              data-aos-delay="1000"
              data-aos-duration="1500"
              src={scopeIMG}
              alt={APP_NAME}
            />
          </ImgWrapper3>
          <ImgWrapper4 className="animate floating">
            <img
              data-aos="fade-up"
              data-aos-delay="2000"
              data-aos-duration="1500"
              src={bottleIMG}
              alt={APP_NAME}
            />
          </ImgWrapper4>
        </RightSide>
      </Wrapper>
    </div>
  );
};

const ImgWrapper1 = styled.div`
  position: absolute;
  height: 350px;
  margin-bottom: 25%;
  left: -12%;

  &:hover {
    scale: 1.1;
  }

  img {
    height: 100%;
  }

  @media (max-width: 960px) {
    height: 200px;
    left: 0;
    margin: 0;
    margin-bottom: 20%;
  }
`;

const ImgWrapper2 = styled.div`
  position: absolute;
  height: 150px;
  margin-top: 55%;
  left: 5%;

  &:hover {
    scale: 1.1;
  }

  img {
    height: 100%;
  }

  @media (max-width: 960px) {
    height: 100px;
    left: 17%;
    margin: 0;
    margin-bottom: -50%;
  }
`;

const ImgWrapper3 = styled.div`
  position: absolute;
  rotate: -25deg;
  height: 400px;
  right: -25%;

  &:hover {
    scale: 1.1;
  }

  img {
    height: 100%;
  }

  @media (max-width: 960px) {
    height: 150px;
    rotate: 0deg;
    left: auto;
    right: 0;
    margin: 0;
    margin-bottom: 28%;
  }
`;

const ImgWrapper4 = styled.div`
  position: absolute;
  rotate: -20deg;
  height: 150px;
  margin-bottom: -55%;
  margin-right: 15%;

  &:hover {
    scale: 1.1;
  }

  img {
    height: 100%;
  }

  @media (max-width: 960px) {
    height: 100px;
    left: auto;
    margin: 0;
    margin-bottom: -40%;
    margin-right: -17%;
  }
`;

const Wrapper = styled.section`
  display: flex;
  align-items: stretch;
  padding-top: 100px;
  width: 100%;
  gap: 50px;
  min-height: 100vh;
  @media (max-width: 960px) {
    flex-direction: column;
    min-height: 100%;
    gap: 10px;
  }
`;

const LeftSide = styled.div`
  justify-content: center;
  width: 50%;
  gap: 30px;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin: 70px 0;
    text-align: center;
  }
`;

const RightSide = styled.div`
  transform: perspective(1000px) rotateY(11deg);
  position: relative;
  width: 50%;
  border-radius: 50px;
  margin: 70px 0;
  @media (max-width: 960px) {
    transform: none;
    min-height: 250px;
    border-radius: 30px;
    margin: 0 0 80px 0;
    width: 100%;
    order: 2;
    text-align: center;
  }
`;

const CenterWrapper = styled.div`
  width: 100%;
  @media (max-width: 960px) {
    display: flex;
    justify-content: center;
  }
`;

const TextWrapper = styled.div`
  width: 90%;
  @media (max-width: 960px) {
    width: 100%;
    padding: 0 20px;
  }
`;
