import React from "react";
import styled from "styled-components";
import { productList } from "../constants/siteData";

const ProductItem = ({ data: { label, imageUrl, props } }) => {
  return (
    <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
      <ItemWrapper className="animate flex" image={imageUrl} {...props}>
        <p className="whiteColor semiBold font15">{label}</p>
      </ItemWrapper>
    </div>
  );
};

export const Products = () => {
  return (
    <Wrapper className="pokaPattern" id="products">
      <div className="container flexColumn gap20">
        <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="0">
          <h1 className="font40 colorSecondary textCenter">Our Products</h1>
        </div>
        <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
          <p className="textCenter">
            We Offer <b>1000+</b> Essential Medications Across Various
            Categories.
          </p>
        </div>

        <GridWrapper>
          {productList.map((data) => (
            <ProductItem data={data} />
          ))}
        </GridWrapper>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  padding: 120px 0;
  @media (max-width: 960px) {
    padding: 70px 0;
  }
`;

const GridWrapper = styled.div`
  width: 100%;
  gap: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 50px;

  @media (max-width: 960px) {
    gap: 10px;
    grid-template-columns: repeat(2, 1fr);
    padding: 0 10px;

    :last-child {
      grid-column: 1 / -1;
    }
  }
`;

const ItemWrapper = styled.div`
  min-height: 300px;
  align-items: flex-end;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: ${({ backgroundPosition }) =>
    backgroundPosition ? backgroundPosition : "center center"};
  padding: 20px;
  border-radius: 15px;

  background-image: ${(
    props
  ) => `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5)),
  url(${props.image})`};

  // &:hover {
  // scale: 1.03;
  // font-size: 20px;
  // }

  @media (max-width: 960px) {
    min-height: 200px;
  }
`;
