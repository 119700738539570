// colors
export const primaryColor = "#009151";
export const secondaryColor = "#0a4f90";

export const antdTheme = {
  token: {
    fontFamily: "DM Sans",
    colorPrimary: primaryColor,
    borderRadius: 10,
  },
};
