import { ReactComponent as iCare } from "./care.svg";
import { ReactComponent as iDelivery } from "./delivery.svg";
import { ReactComponent as iExpert } from "./expert.svg";
import { ReactComponent as iPrice } from "./price.svg";
import { ReactComponent as iQuality } from "./quality.svg";
import { ReactComponent as iSafety } from "./safety.svg";
import { ReactComponent as iLogo } from "./logo.svg";

export const LogoSVG = iLogo;
export const CareSVG = iCare;
export const DeliverySVG = iDelivery;
export const ExpertSVG = iExpert;
export const PriceSVG = iPrice;
export const QualitySVG = iQuality;
export const SafetySVG = iSafety;
