import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";
import styled from "styled-components";
import { navLinks } from "../constants/links";
import { IconButton } from "@mui/material";
import { MenuRounded } from "@mui/icons-material";
import { MainButton } from "./common/Buttons";
import { Logo } from "./common/Logo";
import Sidebar from "./Sidebar";
import Backdrop from "./elements/Backdrop";
import { callToAction } from "../utils/Utils";
import { LogoSVG } from "../assets/svg/Svgs";

export const navStyle = { padding: "10px 20px" };

export const TopNavBar = () => {
  const [y, setY] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);

  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      <Wrapper
        className="flexCenter animate"
        style={
          y > 100
            ? {
                height: "70px",
              }
            : { height: "100px" }
        }
      >
        <NavInner className="container flexSpaceCenter">
          <Link
            className="pointer flexNullCenter"
            to="home"
            duration={2000}
            smooth={true}
          >
            <LogoSVG height="100%" width={120} />
          </Link>

          <UlWrapper className="flexNullCenter flexGrow">
            {navLinks.map((val, i) => {
              const { label, offset } = val;
              return (
                <li className="semiBold font14 pointer" key={i}>
                  <Link
                    className="animate"
                    activeClass="active-link"
                    style={navStyle}
                    to={label.toLowerCase()}
                    spy={true}
                    smooth={true}
                    offset={offset}
                  >
                    {label}
                  </Link>
                </li>
              );
            })}
          </UlWrapper>

          <UlWrapperRight className="flexNullCenter">
            <MainButton onClick={callToAction}>Call to Order</MainButton>
          </UlWrapperRight>

          <MenuWrapper className="borderBox radius6">
            <IconButton onClick={() => toggleSidebar(!sidebarOpen)}>
              <MenuRounded />
            </IconButton>
          </MenuWrapper>
        </NavInner>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  box-shadow: inset 0px -1px 1px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8px);
  background-color: rgba(255, 255, 255, 0.8);
  top: 0;
  left: 0;
  z-index: 999;
`;
const NavInner = styled.div`
  position: relative;
  gap: 70px;
  height: 100%;
`;

const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 760px) {
    display: none;
  }
`;
const UlWrapperRight = styled.ul`
  @media (max-width: 760px) {
    display: none;
  }
`;

const MenuWrapper = styled.div`
  display: none;
  @media (max-width: 760px) {
    display: flex;
  }
`;
