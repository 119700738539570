import {
  babyIMG,
  eyeIMG,
  familyIMG,
  firstAidIMG,
  foodIMG,
  healthIMG,
  painIMG,
  skinIMG,
  sleepIMG,
} from "../assets/img/Images";
import {
  CareSVG,
  DeliverySVG,
  ExpertSVG,
  PriceSVG,
  QualitySVG,
  SafetySVG,
} from "../assets/svg/Svgs";

export const headerText = (
  <>
    We are a <b>full-service</b> pharmacy dedicated to providing exceptional
    care and personalized attention to every customer.
  </>
);

export const productList = [
  {
    imageUrl: eyeIMG,
    label: "Eye Care",
    props: {
      backgroundPosition: "left center",
    },
  },
  {
    imageUrl: skinIMG,
    label: "Skin & Scalp Care",
  },
  {
    imageUrl: painIMG,
    label: "Pain Relief",
  },
  {
    imageUrl: healthIMG,
    label: "Health Supplements",
  },
  {
    imageUrl: foodIMG,
    label: "Food & Nutrition",
  },
  {
    imageUrl: babyIMG,
    label: "Baby Health & Care",
  },
  {
    imageUrl: firstAidIMG,
    label: "First Aid",
  },
  {
    imageUrl: familyIMG,
    label: "Family Planning",
  },
  {
    imageUrl: sleepIMG,
    label: "Sleep & Stress Relief",
  },
];

export const whyOptions = [
  {
    label: "Expert Guidance",
    desc: "Our team of experienced pharmacists is here to offer personalized advice and answer all your questions regarding medications and overall health management.",
    icon: ExpertSVG,
  },
  {
    label: "Personalized Care",
    desc: "At One Life Pharmacy, we understand that every individual has unique healthcare needs. That's why we take the time to get to know you personally and tailor our services to meet your specific requirements.",
    icon: CareSVG,
  },
  {
    label: "Quality Products",
    desc: "Rest assured knowing that we only stock the highest quality medications and healthcare products sourced from reputable manufacturers, ensuring both safety and effectiveness.",
    icon: QualitySVG,
  },
  {
    label: "Safety First",
    desc: "Your safety is our utmost priority. We adhere to stringent safety protocols to ensure accurate dispensing of medications and handle every interaction with the utmost care and attention.",
    icon: SafetySVG,
  },
  {
    label: "Competitive Prices",
    desc: "We believe that access to affordable healthcare is a fundamental right. That's why we strive to offer competitive prices on all our products and services, making quality healthcare accessible to all.",
    icon: PriceSVG,
  },
  {
    label: "Last-mile Delivery",
    desc: "We understand the importance of reliability in last mile delivery. Our robust infrastructure and experienced team ensure that your packages are delivered safely and on time, every time.",
    icon: DeliverySVG,
  },
];

export const faqItems = [
  {
    label: "What is One Life Pharmacy?",
    children:
      "At our pharmacy, we believe that taking care of your health should never be a hassle. So whether you're managing a chronic condition or just looking for ways to improve your overall wellness, we're here to help you every step of the way. Thank you for choosing us as your trusted source for all of your healthcare needs.",
  },
  {
    label: "How can I refill my prescription?",
    children:
      "Refilling your prescription is easy! You can either call our pharmacy directly, use our online refill request form on our website, or use our mobile app to submit your refill request.",
  },
  {
    label: "What if I need my medication delivered?",
    children:
      "We offer free local delivery for your convenience. Just let us know when you refill your prescription, and we'll arrange for a delivery time that works for you.",
  },
  {
    label:
      "How do I know if a medication has potential side effects or interactions?",
    children:
      "Our knowledgeable pharmacists are here to answer any questions you have about your medications, including potential side effects and interactions. Feel free to ask us anything during your visit or give us a call.",
  },
  {
    label: "What payment methods do you accept?",
    children:
      "We accept various payment methods, including cash, credit cards, debit cards and mobile money",
  },
  {
    label: "Do you offer counseling or  consultations?",
    children:
      "Yes, our pharmacists are available to provide medication counseling and answer any questions you have about your prescriptions. Whether you have concerns about dosage, administration, or potential side effects, we're here to help.",
  },
  {
    label:
      "What should I do if I experience a problem with my medication or have an adverse reaction?",
    children:
      "If you experience any problems or adverse reactions with your medication, please stop taking it immediately and contact us or seek medical attention. Our pharmacists can provide guidance on what steps to take next.",
  },
];

export const footerServices = ["Prescription", "Insurance", "Delivery"];

export const footerCompany = ["Products", "Why Us", "FAQs"];
