import {
  FacebookRounded,
  Instagram,
  LinkedIn,
  YouTube,
} from "@mui/icons-material";

export const WHATSAPP_URL = "https://wa.me/256709752707";

export const navLinks = [
  {
    label: "Home",
  },
  {
    label: "Products",
    offset: 0,
  },
  {
    label: "Why Us",
    offset: 10,
  },
  {
    label: "FAQs",
    offset: 10,
  },
  {
    label: "Contact Us",
    offset: 0,
  },
];

export const socialLinks = [
  {
    Icon: FacebookRounded,
    link: "#",
  },
  {
    Icon: Instagram,
    link: "#",
  },
  {
    Icon: LinkedIn,
    link: "#",
  },
  {
    Icon: YouTube,
    link: "#",
  },
];
