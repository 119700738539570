import React from "react";
import { Header } from "../sections/Header";
import { Products } from "../sections/Products";
import { Why } from "../sections/Why";
import { Faqs } from "../sections/Faqs";
import { Footer } from "../sections/Footer";
import { Divider } from "../components/common/Styled";

export const Home = () => {
  return (
    <>
      <Header />
      <Products />
      <Why />
      <Divider className="container" />
      <Faqs />
      <Divider className="container" />
      <Footer />
    </>
  );
};
