import React from "react";
import styled from "styled-components";
import { faqItems } from "../constants/siteData";
import { Collapse } from "antd";

export const Faqs = () => {
  return (
    <Wrapper id="faqs" className="container flexColumn gap20">
      <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="0">
        <h1 className="font40 colorSecondary textCenter">FAQs</h1>
      </div>
      <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
        <p className="textCenter">Questions? We've got you covered.</p>
      </div>
      <div
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="300"
        style={{ marginTop: 20 }}
      >
        <Collapse
          style={{ margin: 20 }}
          accordion
          items={faqItems.map(({ label, children }, i) => {
            return {
              label,
              key: i,
              children: <p className="font14 light greyColor">{children}</p>,
            };
          })}
          defaultActiveKey={[0]}
        />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  padding: 120px 0;
  @media (max-width: 960px) {
    padding: 70px 0;
  }
`;
