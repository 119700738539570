import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
import { WHATSAPP_URL, socialLinks } from "../constants/links";
import { footerCompany, footerServices } from "../constants/siteData";
import {
  APP_EMAIL,
  APP_NAME,
  APP_PHONE,
  APP_WHATSAPP,
} from "../constants/strings";
import { secondaryColor } from "../theme/Theme";
import {
  EmailOutlined,
  LocationOnOutlined,
  PhoneOutlined,
  WhatsApp,
} from "@mui/icons-material";
import { LogoSVG } from "../assets/svg/Svgs";
import { HyperLink } from "../components/common/Common";
import { Button } from "@mui/material";
import { MainButton } from "../components/common/Buttons";
import { openLink } from "../utils/Utils";

export const getCurrentYear = () => {
  return new Date().getFullYear();
};

export const IconText = ({ icon: Icon, children, iconSize }) => {
  return (
    <div className="font14 light flex gap15">
      <Icon sx={{ fontSize: iconSize || 20 }} />
      <div>{children}</div>
    </div>
  );
};

export const Footer = () => {
  return (
    <div id="contact us">
      <Wrapper>
        <div
          className="container"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="0"
        >
          <GridWrapper>
            <SectionWrapper>
              <LogoSVG height="100%" width={150} />

              <div className="flexColumn gap15">
                <IconText icon={LocationOnOutlined} iconSize={22}>
                  <p>
                    Ubuntu Business Centre, <br />
                    Kisaasi, Kulambiro Road
                  </p>
                </IconText>

                <IconText icon={PhoneOutlined}>
                  <HyperLink type="tel">{APP_PHONE}</HyperLink>
                  <p style={{ marginTop: 5 }}>
                    <HyperLink type="tel">{APP_WHATSAPP}</HyperLink>
                  </p>
                </IconText>

                <IconText icon={EmailOutlined}>
                  <HyperLink type="email">{APP_EMAIL}</HyperLink>
                </IconText>
              </div>
            </SectionWrapper>
            <SectionWrapper>
              <h4>Company</h4>
              {footerCompany.map((label) => (
                <Link
                  className="font14 light pointer"
                  smooth
                  to={label.toLowerCase()}
                >
                  {label}
                </Link>
              ))}
            </SectionWrapper>
            <SectionWrapper>
              <h4>Services</h4>
              {footerServices.map((val) => (
                <a
                  // href="#"
                  className="font14 light pointer"
                >
                  {val}
                </a>
              ))}
            </SectionWrapper>
            <SectionWrapper>
              <h4>Working Hours</h4>
              <p className="font14 light">
                6:00am - 12:00am <p className="font14 light">Monday - Sunday</p>
              </p>

              <div className="flex gap10">
                {socialLinks.map(({ Icon, link }, i) => (
                  <a
                    key={i}
                    // href={link}
                    className="animate pointer colorSecondary flex lightLink"
                  >
                    <Icon style={{ fontSize: 22 }} />
                  </a>
                ))}
              </div>
            </SectionWrapper>
          </GridWrapper>
        </div>
      </Wrapper>

      {/* MESSAGE BANNER */}

      <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300">
        <BannerWrapper className="flexNullCenter gap20 container">
          <p className="font14 light">
            Message <b>Onelife</b> Pharmacy on WhatsApp.
          </p>
          <MainButton icon={WhatsApp} onClick={() => openLink(WHATSAPP_URL)}>
            Send WhatsApp
          </MainButton>
        </BannerWrapper>
      </div>

      {/* COPYRIGHT */}
      <CopyrightWrapper className="lightBg">
        <p className="font13 textCenter">
          © 2022 - {getCurrentYear()} |{" "}
          <span className="colorSecondary">{APP_NAME}</span>. All Rights
          Reserved.
        </p>
      </CopyrightWrapper>
    </div>
  );
};

const Wrapper = styled.section`
  padding-block: 100px 20px;
  @media (max-width: 960px) {
    padding: 50px 0;
  }
`;

const CopyrightWrapper = styled.div`
  padding: 40px 0;
`;

const BannerWrapper = styled.div`
  // border-top: 0.5px solid rgb(0, 0, 0, 0.1);
  padding-bottom: 100px;
  padding-right: 80px;
  justify-content: flex-end;
  @media (max-width: 960px) {
    flex-direction: column;
    padding-bottom: 50px;
    padding-right: 0;
  }
`;

const SectionWrapper = styled.div`
  gap: 20px;
  display: flex;
  flex-direction: column;

  h4 {
    margin-bottom: 10px;
    color: ${secondaryColor};
  }

  @media (max-width: 550px) {
    width: 100%;
    gap: 15px;
    padding: 20px;

    h4 {
      margin-bottom: 0;
    }
  }
`;

const GridWrapper = styled.div`
  width: 100%;
  gap: 20px;
  display: grid;
  grid-template-columns: 3fr 1fr 1fr 1fr;

  @media (max-width: 960px) {
    display: flex;
    flex-direction: column;
  }
`;
