import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { ConfigProvider } from "antd";
import { antdTheme } from "./theme/Theme";
import { BrowserRouter } from "react-router-dom";
import { MainContainer } from "./components/containers/MainContainer";

function App() {
  useEffect(() => {
    Aos.init();
    Aos.refresh();
  }, []);

  return (
    <ConfigProvider theme={antdTheme}>
      <BrowserRouter>
        <MainContainer />
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
